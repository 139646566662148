<template>
  <div class="content-wrap">
    <div class="content-select">
      <trade-basic
        scene="RI"
        :coo-role="cooRole"
        :settlement="settlement"
        v-loading="settlementLoading"
      ></trade-basic>
    </div>
    <div class="content-main">
      <div>
        <div class="content-header" style="margin-bottom: 12px">
          <span>发票清单</span>
          <div>
            <el-button
              type="primary"
              @click="handleQuery"
              v-hasPermi="['coo:trade-receiving:list']"
              >查询</el-button
            >
            <el-button
              type="primary"
              :disabled="receiveBtnDisabled"
              @click="handleInvoiceUpload('localup')"
              v-hasPermi="['coo:trade-receiving:upload']"
              >上传发票</el-button
            >
            <el-button
              type="primary"
              :disabled="receiveBtnDisabled"
              @click="handleInvoiceUpload('scanner')"
              v-hasPermi="['coo:trade-receiving:upload']"
              >扫描发票</el-button
            >
          </div>
        </div>
        <div class="table-main">
          <el-table
            border
            :data="tableData"
            ref="previewTable"
            v-loading="dataLoading"
            highlight-current-row
            show-summary
            :summary-method="getTableSummary"
            :header-cell-style="handleHeaderCellStyle"
          >
            <el-table-column
              type="index"
              fixed="left"
              label="序号"
              width="60"
            ></el-table-column>
            <el-table-column
              prop="colInvoice.invoiceType"
              fixed="left"
              label="发票类型"
              min-width="120"
              :formatter="fmtInvoiceType"
            ></el-table-column>
            <el-table-column
              prop="colInvoice.invoiceNo"
              fixed="left"
              label="发票号码"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="colInvoice.invoiceCode"
              fixed="left"
              label="发票代码"
              width="140"
            ></el-table-column>
            <el-table-column
              prop="colInvoice.eInvoiceNo"
              fixed="left"
              label="数电票号"
              width="180"
            ></el-table-column>
            <el-table-column
              prop="colInvoice.invoiceTime"
              label="开票日期"
              width="120"
              header-align="center"
              align="center"
              :formatter="fmtInvoiceTime"
            ></el-table-column>
            <el-table-column
              prop="colInvoice.receiveTime"
              label="交票日期"
              width="180"
              :formatter="fmtReceiveTime"
            ></el-table-column>
            <el-table-column
              prop="colRelation.invoicingType"
              label="协同开票"
              width="100"
              header-align="center"
              align="center"
              :formatter="fmtInvoicingType"
            ></el-table-column>
            <el-table-column
              prop="colInvoice.amount"
              label="未税金额"
              min-width="100"
              header-align="right"
              align="right"
              :formatter="fmtInvoiceAmount"
            ></el-table-column>
            <el-table-column
              prop="colInvoice.taxAmount"
              label="发票税额"
              min-width="100"
              header-align="right"
              align="right"
              :formatter="fmtInvoiceAmount"
            ></el-table-column>
            <el-table-column
              prop="colInvoice.sumAmount"
              label="价税合计"
              min-width="100"
              header-align="right"
              align="right"
              :formatter="fmtInvoiceAmount"
            ></el-table-column>
            <el-table-column
              prop="operate"
              label="操作"
              header-align="center"
              align="center"
              fixed="right"
              width="120"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="handlePreviewImage(scope.row)"
                  >影像</el-button
                >
                <el-button type="text" @click="handleTicketDetail(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="content-page">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="receiveParam.pageNo"
          :page-sizes="pageSizes"
          :page-size="receiveParam.pageSize"
          layout="total, sizes, prev, pager, next"
          :total="dataTotal"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 票据影像弹窗 -->
    <invoice-img :invoiceImg="invoiceImg"> </invoice-img>

    <!-- 发票影像预览 -->
    <el-dialog width="80%" title="发票影像" :visible.sync="invoiceImageVisible">
      <col-invoice-image
        v-if="invoiceImageVisible"
        :col-invoice-id="colInvoiceId"
      ></col-invoice-image>
    </el-dialog>

    <!-- 票据详情弹窗 -->
    <invoice-detail
      :loading="loading"
      :show.sync="drawer"
      :invoice="invoice"
    ></invoice-detail>
  </div>
</template>

<script>
import ColInvoiceImage from "@/views/invoice-center/component/ColInvoiceImage.vue";
import TradeBasic from "@/views/cooperate/settlement/components/tradeBasic.vue";
import InvoiceDetail from "@/components/invoice/InvoiceDetail";
import InvoiceImg from "@/components/invoice/InvoiceImg";
import { fmtCurrency } from "@/assets/js/format-util";

import {
  tableStyle,
  pagingMethods,
  dateOption,
  changeMethods,
} from "@/util/mixins";

import { reqSelectSettlement } from "@/service/coo/trade.js";
import { reqPagingSettlementRelInvoice } from "@/service/coo/trade-rel-invoice.js";
import {
  selectInvoice,
} from "@/service/invoice-center";

export default {
  name: "CooTradeReceivingDetail",
  components: { InvoiceImg, TradeBasic, InvoiceDetail, ColInvoiceImage },
  mixins: [tableStyle, pagingMethods, dateOption, changeMethods],
  props: {
    cooRole: {
      type: String,
      default: "SS",
    },
  },
  data() {
    return {
      // 订单详情
      settlementId: this.$route.query.id,
      settlement: {},
      settlementLoading: false,

      // 查询条件
      receiveParam: {
        oriTradeId: this.$route.query.id,
        pageNo: 1,
        pageSize: 50,
      },

      // 收票列表
      dataTotal: 0,
      tableData: [],
      dataLoading: false,

      invoiceImg: {
        visible: false,
        url: "",
      },

      invoicingTypeList: [],
      loading: false,
      drawer: false,
      invoice: {},
      invoiceImageVisible: false,
      colInvoiceId: undefined,
    };
  },
  computed: {
    receiveBtnDisabled() {
      return (
        !this.settlement.id ||
        ["CS", "WJ"].indexOf(this.settlement.sheetStage) != -1
      );
    },
  },
  async created() {
    this.$watch(
      () => this.$route.path,
      async () => {
        await this.initDict();
        this.queryTrade();
        this.handleQuery();
      }
    );

    await this.initDict();
    this.queryTrade();
    this.handleQuery();
  },
  methods: {
    // 切换分页条数
    handleSizeChange(value) {
      this.receiveParam.pageNo = 1;
      this.receiveParam.pageSize = value;
      this.pagingSettlementReceiving();
    },
    // 收票列表翻页
    handleCurrentChange(value) {
      this.receiveParam.pageNo = value;
      this.pagingSettlementReceiving();
    },
    // 查询订单详情
    async queryTrade() {
      this.settlementLoading = true;
      const { success, data } = await reqSelectSettlement(this.settlementId);
      if (success) {
        this.settlement = data;
      }
      this.settlementLoading = false;
    },
    // 浏览发票影像
    handlePreviewImage(row) {
      this.colInvoiceId = row.colInvoice.id;
      this.invoiceImageVisible = true;
    },

    // 查询交票列表
    handleQuery() {
      this.receiveParam.pageNo = 1;
      this.pagingSettlementReceiving();
    },

    // 加载数据字典
    async initDict() {
      const { data } = await this.batchGetDicts({
        types: ["cooperation_invoicing_type"],
      });
      this.invoicingTypeList = data["cooperation_invoicing_type"];
    },
    // 查看发票详情
    handleTicketDetail(row) {
      this.drawer = !this.drawer;
      let invoice = row.colInvoice;
      selectInvoice(invoice.id).then((res) => {
        if (res.success) {
          this.loading = false;
          let details = res.data.items;
          if (details) {
            details.forEach(
              (item) => (item.taxRate = Number(item.taxRate) * 100 + "%")
            );
            // 统计信息
            let total = {
              merchandiseName: "合计",
              amount: invoice.amount ? invoice.amount : 0,
              taxAmount: invoice.taxAmount ? invoice.taxAmount : 0,
            };
            details.push(total);
            let sum = Number(invoice.sumAmount).toFixed(2);
            let sumBig = this.handleSmallToCapitalize(sum);
            let priceTax = {
              merchandiseName: "价税合计（大写）",
              specification: sumBig + "  " + "（小写）￥" + sum,
            };
            details.push(priceTax);
          }
          let invoiceData = res.data;
          invoiceData.items = details;
          this.invoice = invoiceData;
        }
      });
    },
    // 查询交票列表
    async pagingSettlementReceiving() {
      this.dataLoading = true;
      const { success, data } = await reqPagingSettlementRelInvoice(
        this.receiveParam
      );
      if (success) {
        this.tableData = data.list;
        this.dataTotal = data.total;
      }
      this.dataLoading = false;
    },
    // 格式开票时间
    fmtInvoiceTime(row) {
      let value = row.colInvoice.invoiceTime;
      if (value != undefined) {
        return this.$moment(value).format("YYYY-MM-DD");
      }
      return "--";
    },
    // 格式收票时间
    fmtReceiveTime(row) {
      let value = row.colInvoice.receiveTime;
      if (value != undefined) {
        return this.$moment(value).format("YYYY-MM-DD HH:mm:ss");
      }
      return "--";
    },
    // 格式发票类型
    fmtInvoiceType(row, column, value) {
      return this.handleValueToLabel("InvoiceType", value);
    },
    // 格式金额字段
    fmtInvoiceAmount(row, column, value) {
      return fmtCurrency(value, 2, "", ",", ".", "");
    },
    // 协同开票方式
    fmtInvoicingType(row, column, value) {
      return this.getDictLabel(this.invoicingTypeList, value);
    },
    // 结算交票上传
    async handleInvoiceUpload(type) {
      let path = `/coo/${
        this.cooRole == "DS" ? "buy" : "sal"
      }/trade/receiving/${type}`;

      this.$router.replace({
        path: path,
        query: {
          sceneName: "JS",
          settlementId: this.settlementId,
          sceneRole: this.cooRole,
        },
      });
    },
    // 计算收票汇总
    getTableSummary(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index == 0) {
          sums[index] = "汇总";
        } else {
          let idx = [
            "colInvoice.amount",
            "colInvoice.taxAmount",
            "colInvoice.sumAmount",
          ].indexOf(`${column.property}`);
          if (idx == -1) {
            sums[index] = "";
          } else {
            const values = data.map((item) => {
              if (idx == 0) {
                return Number(item.colInvoice.amount);
              } else if (idx == 1) {
                return Number(item.colInvoice.taxAmount);
              } else {
                return Number(item.colInvoice.sumAmount);
              }
            });

            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + value;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] = Number(sums[index]).toFixed(2) + " 元";
            } else {
              sums[index] = "";
            }
          }
        }
      });

      return sums;
    },
  },
};
</script>

<style lang="scss" scoped>
.imagePreviewVisibleHeight {
  ::v-deep .el-dialog {
    height: 100%;
    margin: 0;
    margin-top: 1vh;
  }
  ::v-deep .el-dialog__headerbtn {
    font-size: 26px;
  }
}
.content-select {
  box-sizing: border-box;
  padding: 20px;
  background-color: #ffffff;
}
.select-content {
  display: flex;
  flex-direction: column;

  label {
    width: 80px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
  }

  span {
    margin-bottom: 12px;
    width: 176px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
  }
}
.table-main {
  .el-button {
    padding: 0;
  }
}

.table-statistics {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: left;
  background: #ebf4ff;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;

  ::v-deep div {
    margin-left: 25px;
  }
  ::v-deep div:first-child {
    margin-right: 30%;
  }
}

.content-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 140px);

  .content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
    }

    span:before {
      content: "|";
      font-size: 16px;
      margin-right: 10px;
      display: inline-block;
      background-color: #333;
    }
  }
}
</style>
